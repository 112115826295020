import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import MicNoneIcon from "@mui/icons-material/MicNone";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { blobToAudioBuffer, webSocketUrlV1 } from "utils/configs";
import useWebSocket, { ReadyState } from "react-use-websocket";
import RecordRTC from "recordrtc";

export default function RecordRTCComponent({
  text,
  setText,
  tempText,
  setTempText,
}: {
  text: string;
  setText: (result: string) => void;
  tempText: string;
  setTempText: (result: string) => void;
}) {
  const [isStreaming, setIsStreaming] = useState(false);
  //   const [record, setRecord] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [socketUrl, setSocketUrl] = useState(webSocketUrlV1);
  const [messageHistory, setMessageHistory] = useState([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => console.log("WebSocket connection opened."),
    onClose: () => console.log("WebSocket connection closed."),
    shouldReconnect: () => true,
    onMessage: (event: WebSocketEventMap["message"]) => {
      const message = JSON.parse(event.data);
      console.log("Response: ", message);
      //   const newMessage = message?.text ? text + message?.text : text;
      //   const newMessage = message?.partial ? text + message?.partial : text;
      //   setText(text + message?.partial);

      if (message?.text) {
        setText(text + message?.text);
        setTempText("");
      } else if (message?.partial) {
        setTempText(text + message?.partial);
      }
    },
    onError: (error) => console.log("WebSocket error:", error),
  });

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  //   const initRecorder = async () => {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //       video: false,
  //     });
  //     this.recorder = new RecordRTC(stream, {
  //       type: "audio",
  //       mimeType: "audio/wav",
  //       desiredSampRate: 16000,
  //       audioBitsPerSecond: 16000,
  //       timeSlice: 500,
  //       recorderType: RecordRTC.StereoAudioRecorder,
  //       numberOfAudioChannels: 1,
  //       ondataavailable: function (blob) {
  //         // POST/PUT "Blob" using FormData/XHR2
  //         console.log("blob", blob);
  //         // var blobURL = URL.createObjectURL(blob);
  //         // document.write('<a href="' + blobURL + '">' + blobURL + '</a>');
  //       },
  //     });
  //   };

  const startRecording = async () => {
    setIsStreaming(true);
    setRecorder(true);
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    const recorder = new RecordRTC(stream, {
      type: "audio",
      mimeType: "audio/wav",
      desiredSampRate: 16000,
      audioBitsPerSecond: 16000,
      timeSlice: 100,
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 1,
      ondataavailable: function (blob) {
        onData(blob);
      },
    });
    recorder.startRecording();
    setRecorder(recorder);
  };

  const stopRecording = async () => {
    setIsStreaming(false);
    setRecorder(false);
    recorder.stopRecording(function () {
      var blob = recorder.getBlob();
      console.log("blob", blob);
      // var blobURL = URL.createObjectURL(blob);
      // document.write('<a href="' + blobURL + '">' + blobURL + '</a>');
    });
  };

  const handleSendMessage = useCallback((data) => sendMessage(data), []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "កំពុងភ្ជាប់",
    [ReadyState.OPEN]: "និយាយ",
    [ReadyState.CLOSING]: "កំពុងបិទ",
    [ReadyState.CLOSED]: "តំណត្រូវបានផ្ដាច់",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const onData = async (recordedBlob: Blob) => {
    // var _blob = new Blob([recordedBlob], { type: "audio/wav; codecs=pcm" });
    // const bytes = await recordedBlob.arrayBuffer();
    // const uint8Array = await _blob
    //   .arrayBuffer()
    //   .then((buf) => new Uint8Array(buf));

    // console.log("chunk of real-time data is: ", _blob);
    // console.log("bytes: ", bytes);
    // console.log("uint8Array: ", uint8Array);

    handleSendMessage(recordedBlob);
  };

  const onBlock = async () => {
    console.error("onBlock", "User blocked the permission request.");
  };

  const onStop = async () => {
    // const url = URL.createObjectURL(recordedBlob.blob);
    // const byteArray = await blobUrlToBytes(url);
    // handleSendMessage(byteArray)
    // log byteArray to console
    // console.log("byteArray: ", byteArray);
    //
    // setMyAudioSrc(url);
    // const audio = new Audio(url)
    // await audio.play()
    // handleSendMessage(byteArray)
  };

  useEffect(() => {
    if (connectionStatus === "Open") {
    }
    return () => {};
  }, []);

  // @ts-ignore
  return (
    <div>
      <div>
        <div>
          <Stack spacing={2} alignItems="center">
            {!isStreaming ? (
              <Box
                sx={{ position: "relative", width: "80px", height: "80px" }}
                onClick={startRecording}
              >
                <IconButton
                  sx={{
                    zIndex: 3,
                    backgroundColor: "primary.light",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      opacity: 0.8,
                    },
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <MicNoneIcon sx={{ fontSize: "2rem", color: "black" }} />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{ position: "relative", width: "80px", height: "80px" }}
                onClick={stopRecording}
              >
                {
                  <>
                    <Box
                      sx={{
                        backgroundColor: "#3366ff",
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        opacity: 0.4,
                      }}
                      className="animate-ping-200"
                    />
                    <Box
                      sx={{
                        backgroundColor: "#3366ff",
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        opacity: 0.4,
                      }}
                      className="animate-ping animation-delay-1000"
                    />

                    <IconButton
                      sx={{
                        zIndex: 3,
                        backgroundColor: "#3366ff",
                        color: "white",
                        "&:hover": { backgroundColor: "#3366ff", opacity: 0.8 },
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <KeyboardVoiceIcon sx={{ fontSize: "2rem" }} />
                    </IconButton>
                  </>
                }
              </Box>
            )}
          </Stack>
        </div>
      </div>
      {/*<br/>*/}
      {/*<audio controls id="myAudio" src={myAudioSrc}></audio>*/}
      <Box sx={{display:"flex", justifyContent: "center",alignItems: "center", mt: 2}}>
        {connectionStatus}
      </Box>
    </div>
  );
}
