import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// @ts-ignore
import { CopyAllRounded } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ReactMicComponent from "components/ReactMic";
import RecordRTCComponent from "components/RecordRTC";

const Home: React.FC = () => {
  const [duration, setDuration] = React.useState("00:00:00");
  const [text, setText] = React.useState("");
  const [tempText, setTempText] = React.useState("");
  const [base64, setBase64] = React.useState([]);
  const [loading, setLoading] = React.useState(null);

  //   const [socketUrl, setSocketUrl] = useState(webSocketUrlV1);
  //   const [messageHistory, setMessageHistory] = useState([]);

  //   const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
  //     onOpen: () => console.log("WebSocket connection opened."),
  //     onClose: () => console.log("WebSocket connection closed."),
  //     shouldReconnect: (closeEvent) => true,
  //     onMessage: (event: WebSocketEventMap["message"]) => {
  //       const message = JSON.parse(event.data);
  //       const newMessage = message?.text
  //         ? text + message?.text
  //         : message?.partial
  //         ? message?.partial + text
  //         : text;
  //       console.log("Response: ", message);
  //       setText(newMessage);
  //     },
  //     onError: (error) => console.log("WebSocket error:", error),
  //   });

  //   useEffect(() => {
  //     if (lastMessage !== null) {
  //       setMessageHistory((prev) => prev.concat(lastMessage));
  //     }
  //   }, [lastMessage, setMessageHistory]);

  //   const handleSendMessage = useCallback((data) => sendMessage(data), []);

  //   const connectionStatus = {
  //     [ReadyState.CONNECTING]: "Connecting",
  //     [ReadyState.OPEN]: "Open",
  //     [ReadyState.CLOSING]: "Closing",
  //     [ReadyState.CLOSED]: "Connection Closed",
  //     [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  //   }[readyState];

  // function onCopy(text) {
  //   if (text) {
  //     toast.success("Copied to clipboard");
  //   }
  // }

  // function onDelete() {
  //   if (text) {
  //     // delete text
  //     setText("");
  //     toast.success("Delete");
  //   }
  // }

  const onCopy = () => {
    if (text) {
      toast.success("Copied to clipboard");
    }
  };

  const onDelete = () => {
    if (text) {
      // delete text
      setText("");
      toast.success("Delete");
    } else {
      toast.error("Nothing to delete");
    }
    if (tempText) {
      setTempText("");
    }
  };

  return (
    <Container maxWidth={"xl"} sx={{ pd: 4 }}>
      <Stack>
        <Stack sx={{ mb: 2, alignItems: "center" }}>
          <Box
            component="img"
            src="favicon/logo-asr.png"
            sx={{
              width: { xs: 120, sm: 200 },
              height: { xs: 120, sm: 200 },
              alignSelf: "center",
              objectFit: "contain",
              mt: { xs: 0, md: -2, xl: -4 },
              mb: { xs: 0, md: -2, xl: -4 },
            }}
          />
          <Typography
            align="center"
            color={"primary"}
            fontSize={{ xs: "12px", sm: "20px", md: "24px" }}
            fontWeight="bold"
            sx={{
              mt: { xs: 0, md: -2, xl: 0 },
              mb: { xs: 0, md: -2, xl: 0 },
              fontFamily: "CADTMonoDisplay",
            }}
          >
            KHMER AUTOMATIC SPEAK REGONITION (ASR)
          </Typography>
        </Stack>

        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            py: { xs: 2, sm: 0 },
            flexWrap: "wrap",
          }}
        >
          <FormControlLabel
            sx={{ ml: "auto", mb: -2, mr: 0 }}
            control={<Checkbox />}
            label="DONATE MY VOICE TERMS"
          />

          <TextField
            id="khmer-text-input"
            label="សូមនិយាយជាភាសាខ្មែរ"
            multiline
            rows={10}
            value={tempText?.length > 0 ? tempText : text}
            onChange={(e) => setText(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack sx={{ mb: 20 }} spacing={1}>
                    <CopyToClipboard text={text} onCopy={onCopy}>
                      <Tooltip title="Copy">
                        <IconButton>
                          <CopyAllRounded />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                    <Tooltip title="Delete">
                      <IconButton onClick={onDelete}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
      <Stack alignItems="center" sx={{ mt: 1 }} spacing={2}>
        <Typography
          fontWeight="500"
          fontSize="16px"
          sx={{ mb: 2, fontFamily: "Niradei" }}
        >
          ពេលវេលា៖ {duration}
        </Typography>
        <RecordRTCComponent
          text={text}
          setText={setText}
          tempText={tempText}
          setTempText={setTempText}
        />
        {/* {readyState === ReadyState.OPEN ? (
          <>
            <StyledStreamAudio
              onStream={({ duration, data }) => {
                var date = new Date(0);
                date.setSeconds(duration);
                var timeString = date.toISOString().substr(11, 8);
                setDuration(timeString);
                console.log("blob:", data.blob);
                console.log("bytes:", data.byteArray);
                // console.log("uint8Array:", data.uint8Array);
                // console.log("audioBuffer:", data.audioBuffer);

                handleSendMessage(data.byteArray);
              }}
              textState={[text, setText]}
              base64State={[base64, setBase64]}
            />
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "16px", xl: "20px" },
                fontWeight: "600",
                fontFamily: "Niradei",
              }}
            >
              និយាយ
            </Typography>
          </>
        ) : (
          <Typography
            sx={{
              fontSize: { xs: "12px", md: "16px", xl: "20px" },
              fontWeight: "600",
              fontFamily: "Niradei",
            }}
          >
            {connectionStatus}
          </Typography>
        )} */}
        <Box sx={{ mb: 2 }} />
      </Stack>
    </Container>
  );
};

export default Home;
