import { Box, Hidden, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import useOffSetTop from "hooks/useOffSetTop";

const styles = {
  root: {
    transition: "background 400ms ease-in-out",
    top: 0,
    width: "100%",
    zIndex: 5,
    position: "fixed",
  },
};

export default function Layout({ children }: { children: ReactNode }) {
  const isOffset = useOffSetTop(1);

  const getBgColor = () => {
    if (isOffset) {
      return "#fffff";
    }
    return "transparent";
  };

  const dynamicStyles: any = {
    background: {
      ...styles.root,
      bgcolor: getBgColor,
    },
  };

  return (
    <Box sx={dynamicStyles}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: { xs: "60px", md: "80px", xl: "100px" },
          width: "100%",
          boxShadow: isOffset ? 6 : 0,
          px: 2,
          zIndex: 1000,
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: isOffset ? "white" : "transparent",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Box
          component="img"
          src="favicon/logo.png"
          sx={{
            width: { xs: 120, md: 280, xl: 400 },
            height: { xs: 120, md: 280, xl: 400 },
            objectFit: "contain",
          }}
        />
        <Typography
          variant="caption"
          color={"primary"}
          fontFamily="CADTMonoDisplay"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "12px", md: "24px", xl: "24px" },
          }}
        >
          KHMER ASR
        </Typography>
      </Stack>
      <Box sx={{ height: "60px", width: "100%" }} />
      {children}
    </Box>
  );
}
