// export const webSocketUrlV1 = "wss://api.cadt.edu.kh/ws/asr/";
// export const webSocketUrlV2 =
//   "ws://103.16.60.27:6123/api/asr/account/L2Fzcl9jbGllbnRzL2RoamNTODQ2bWxXSnBPc01STVlzL2FwaV9rZXlzL3lJcFprd3VZN3Y4U2R2QU9QQU96";
// const url = `ws://localhost:8000`;
// const url = `wss://test.asr-api.pofdev.com`;
const url = `wss://test.asr-api.idri.edu.kh`;
export const webSocketUrlV1 = `${url}/api/v02/L2Fzcl9jbGllbnRzL1ZuZkRvUmdVQmNNTTI4M1h0ZDJGL2FwaV9rZXlzLzh5emxrUlZ1THJpVmZTOVBhdVFw`;
// "wss://test.asr-api.idri.edu.kh/api/v02/L2Fzcl9jbGllbnRzL1ZuZkRvUmdVQmNNTTI4M1h0ZDJGL2FwaV9rZXlzL0JUaDZ6RUNrRzlEalBpN0c5ekVM";

export function blobToAudioBuffer(blob: Blob): Promise<AudioBuffer> {
  return new Promise((resolve, reject) => {
    const audioContext = new AudioContext();
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      let myArrayBuffer = fileReader.result;

      audioContext.decodeAudioData(
        myArrayBuffer as ArrayBuffer,
        (audioBuffer) => {
          // Do something with audioBuffer
          resolve(audioBuffer);
        }
      );
    };

    //Load blob
    fileReader.readAsArrayBuffer(blob);
  });
}
